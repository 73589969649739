import FingerprintJS from '@fingerprintjs/fingerprintjs'
const { v4: uuidv4 } = require('uuid')
const client = require('@/client')

export default {
  data () {
    return {
      fingerprint: null,
      userData: null
    }
  },
  computed: {
    currentGameId () {

      if (this.$store.state.language === 'sk') {
        return process.env.VUE_APP_GAME_ID_SK
      } else if (this.$store.state.language === 'cz') {
        return process.env.VUE_APP_GAME_ID_CZ
      }

      // defaults to SK
      return process.env.VUE_APP_GAME_ID_SK
    }
  },
  methods: {
    async getFingerprintAndInitializeSession (campaignId) {
      // Get the visitor identifier when you need it.
      const fp = await FingerprintJS.load()
      const result = await fp.get()

      if (result) {
        // This is the visitor identifier:
        // const fingerprint = result.visitorId

        this.userData = {
          screen_resolution: result.components.screenResolution.value,
          platform: result.components.platform.value,
          browser_languages: result.components.languages.value,
          timezone: result.components.timezone.value,
          browser_vendor: result.components.vendor.value
        }

        this.findSession(campaignId)
      }
    },
    findSession (campaignId) {
      if (!this.currentGameId) {
        console.log("No game id retrieved. Cannot track.")
        return false
      }

      const uuid = uuidv4()
      let fingerprint = null

      const identifier = `BRAND_ADVENTURES_SESSION_TSIIC${this.currentGameId}`
      let sessionId = localStorage.getItem(identifier)

      if (!sessionId) {
        localStorage.setItem(identifier, uuid)
        fingerprint = uuid
        console.log("creating session")
      } else {
        fingerprint = sessionId
      }

      console.log(fingerprint)

      this.$store.commit('setSession', fingerprint)

      this.createUpdateSession(fingerprint, campaignId)
    },
    async createUpdateSession(identifier, campaignId) {
      try {
        const res = await client.post(`public/games/${this.currentGameId}/sessions`,
          { identifier: identifier, campaign_id: campaignId, user_data: this.userData }
        )
        if (res) {
          if (res.data.has_finished_game === true) {
            // check if within 24h
            let date = new Date()
            let currentDate = Date.parse(date)
            let last_updated = Date.parse(res.data.game_finished_at)
            let differenceMs = currentDate - last_updated

            if (differenceMs <= (86400*1000)) {
              console.log("Game has already been finished")
              this.$store.commit('setGameFinished', true)
            } else {
              console.log("Game finished but can be played again after 24h")
              this.$store.commit('setGameFinished', false)
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
