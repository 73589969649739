<template>
  <div :class="['finish-wrapper', 'viewport', 'is-fullscreen', 'win-' + this.$store.state.win]" >
    <div class="abs-8 center p-3">
      <img src="@/assets/images/logo_new.png" class="logo">
    </div>

    <div class="flex pt-5 center you-have-won" ref="youHaveWon">
      <div class="shiny up bold shadow">
        <span v-if="nothingWon">
          {{ $t('finish.sorry') }}
          <div class="small mt-5">{{ $t('finish.you_did_not_win') }}</div>
        </span>
        <span v-else>{{ $t('finish.you_have_won') }}</span>
      </div>
    </div>

    <div class="flex"></div>

    <div v-if="nothingWon" class="abs-19 pt-5 center shadow">
      <div ref="win">
        <div class="small bold up">{{ $t('finish.better_luck_next_time') }}</div>
        <div class="mt-3 mb-5">{{ $t('finish.come_back_tomorrow') }}</div>
      </div>
    </div>
    <div v-else class="abs-19 pt-5 center shadow">
      <div ref="win">
        <div class="small bold up" v-if="tattooWon">{{ $t('finish.win_tattoo') }}</div>
        <div class="small bold up" v-if="shotWon" v-html="$t('finish.win_shot')"></div>
        <div class="mb-5">{{ $t('finish.get_your_win_at_the_bar') }}</div>
      </div>
      <div ref="slider" class="center invisible">
        <Slider
          knobColor='#DD5A12'
          arrowColor='#E9E3C7'
          :labelText="$t('finish.slide_to_redeem')"
          @unlocked="sliderUnlocked"
        />
      </div>  
    </div>

    <div ref="responsibly" class="tiny wide up bottom">
      <img class="responsibly-claim" src='@/assets/images/drink-responsibly_sk.svg' v-if="$store.state.language === 'sk'" />
      <img class="responsibly-claim" src='@/assets/images/drink-responsibly_cz.svg' v-else-if="$store.state.language === 'cz'" />
      <img class="responsibly-claim" src='@/assets/images/drink-responsibly.svg' v-else />
    </div>

    <div v-show="showRedeem" class="modal-background"></div>
    <transition name="bounce">
      <div v-show="showRedeem" class="redeem-modal">
        <h2>{{ $t('finish.did_you_get_your_win') }}</h2>
        <div>
          <div class="btn" @click="showRedeem = false">{{ $t('finish.not_yet') }}</div>
          <div class="btn" @click="redeemShot">{{ $t('finish.got_it') }}</div>
        </div>
        <strong>{{ $t('finish.caution') }}</strong>
        {{ $t('finish.by_accepting_you_redeem') }}
      </div>
    </transition>

  </div>
</template>

<script>
import Slider from '@/components/Slider'
import updateSession from '@/mixins/updateSession'

export default {
  components: { Slider },
  mixins: [ updateSession ],
  props: {
    animationStarted: Boolean
  },
  data () {
    return {
      showRedeem: false
    }
  },
  computed: {
    tattooWon () {
      return (this.$store.state.win === 'tattoo')
    },
    shotWon () {
      return (this.$store.state.win === 'shot')
    },
    nothingWon () {
      return (this.$store.state.win === 'nothing')
    }
  },
  mounted () {
    console.log("Finish layer mounted")
    this.resetAnimation()
    // Uncomment for debugging:
    // this.startAnimation()
  },
  methods: {
    resetAnimation() {
      this.$refs.youHaveWon.style.transform = "translateX(-120%)"
      this.$refs.win.style.opacity = 0
      // this.$refs.slider.style.opacity = 0
    },
    startAnimation () {
      const _this = this

      if (!this.nothingWon) {
        this.$refs.slider.style.opacity = 0
      }

      let animation = this
        .$anime
        .timeline({
          autoplay: true,
          duration: 1000,
          easing: 'linear',
          endDelay: 2000,
          complete: () => {
            if (this.nothingWon) {
              this.$store.commit('setGameFinished', true)
            }
          }
        })

      animation
        .add({
          targets: _this.$refs.youHaveWon,
          translateX: 1,
          easing: 'cubicBezier(.5, .05, .1, .5)',
        }, 500)
        .add({
          targets: _this.$refs.win,
          opacity: 1,
        }, 2000)

      if (!this.nothingWon) {
        animation
          .add({
            targets: _this.$refs.slider,
            opacity: 1,
          }, 3000)
      }
    },
    sliderUnlocked () {
      this.showRedeem = true
    },
    redeemShot () {
      this.showRedeem = false
      this.$store.commit('setWin', 'nothing')
      this.$store.commit('setGameFinished', true)

      this.updateSessionProgress(
        { step: 'finish', trigger: 'finish' }
      )
    }
  },
  watch: {
    animationStarted () {
      this.startAnimation()

      this.markSessionAsFinished()

      this.updateSessionProgress(
        { step: 'finish', trigger: 'start' }
      )
    }
  }
}
</script>

<style lang="sass" scoped>
.finish-wrapper
  z-index: 100
  background-size: cover
  background-position: center
  overflow: hidden
  // background-image: url("~@/assets/images/background_with_shot.jpg")

  &.win-nothing
    background-image: url("~@/assets/images/background_with_nothing.jpg")
  
  &.win-tattoo
    background-image: url("~@/assets/images/background_with_tattoo.jpg")

  &.win-shot
    background-image: url("~@/assets/images/background_with_shot.jpg")


.invisible
  opacity: 0
.center
  text-align: center
  
.you-have-won
  font-size: 4.2rem !important
  line-height: 1
  display: flex
  justify-content: center
  align-items: start

.up
  text-transform: uppercase

.bold
  font-weight: bold

.wide
  letter-spacing: 0.3rem

.small
  font-size: 2.2rem

.medium
  font-size: 3rem

.big
  font-size: 6rem

.bottom
  position: absolute
  bottom: 0
  left: 0
  right: 0
  text-align: center
  padding: 2rem

.shadow
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3), 0px 0px 20px #040703

.modal-background
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: linear-gradient(180deg, #0E2001 13.02%, #040703 100%)
  opacity: 0.9
  z-index: 9

.redeem-modal
  position: absolute
  background: linear-gradient(180deg, #193B01 13.02%, #040703 100%)
  box-shadow: 0px -5px 30px #101C0E
  border-radius: 20px
  padding: 5rem 3rem
  top: 25rem
  left: 0
  margin: 0 2rem
  text-align: center
  border: 2px solid
  user-select: none
  z-index: 10

  h2
    font-size: 3rem
    text-transform: uppercase
    margin-bottom: 2rem

  .btn
    font-size: 2rem
    border: 2px solid
    padding: 2rem 1rem
    display: inline-block
    margin: 2rem 0.5rem
    width: 45%
    text-transform: uppercase
    font-weight: bold

    + .btn
      background-color: var(--color-orange)
      border-color: var(--color-orange)

    &:active
      opacity: 0.9

.bounce-enter-active
  animation: bounce-in .3s

.bounce-leave-active
  animation: bounce-in .3s reverse

.responsibly-claim
  height: 1rem !important

.shot-box
  position: relative

  .instagram
    position: absolute
    bottom: 0
    right: 2rem
    width: 10rem
    height: 10rem
    animation: bounce-in 1.5s

@keyframes bounce-in
  0%
    transform: scale(0)
  50%
    transform: scale(1.2)
  100%
    transform: scale(1)

.fog
  z-index: 9
  position: absolute
  left: 0
  bottom: -10rem
  animation: slideFog 100s 1s linear infinite
  will-change: transform
  transform: translateX(-100%)
  pointer-events: none

@keyframes slideFog
  to
    transform: translateX(100%)
</style>
