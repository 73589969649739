<template>
  <div class="ice-wrapper viewport is-fullscreen">
    <SnowEffect />
    <canvas id="sparkleCanvas" />
    <div class="ice">
      <div :class="[`piece piece-1 step-4`]" v-if="stepPiece1 <= 4" @click="stepPiece1 = 5; icecrackfourSound.play()" />
      <div :class="[`piece piece-1 step-3`]" v-if="stepPiece1 <= 3" @click="stepPiece1 = 4; icecrackoneSound.play()" />
      <div :class="[`piece piece-1 step-2`]" v-if="stepPiece1 <= 2" @click="stepPiece1 = 3; icecracktwoSound.play()" />
      <div :class="[`piece piece-1 step-1`]" v-if="stepPiece1 === 1" @click="stepPiece1 = 2; icecrackoneSound.play()" />

      <div :class="[`piece piece-2 step-4`]" v-if="stepPiece2 <= 4" @click="stepPiece2 = 5; icecrackfourSound.play()" />
      <div :class="[`piece piece-2 step-3`]" v-if="stepPiece2 <= 3" @click="stepPiece2 = 4; icecrackoneSound.play()" />
      <div :class="[`piece piece-2 step-2`]" v-if="stepPiece2 <= 2" @click="stepPiece2 = 3; icecracktwoSound.play()" />
      <div :class="[`piece piece-2 step-1`]" v-if="stepPiece2 === 1" @click="stepPiece2 = 2; icecrackoneSound.play()" />

      <div :class="[`piece piece-3 step-4`]" v-if="stepPiece3 <= 4" @click="stepPiece3 = 5; icecrackfourSound.play()" />
      <div :class="[`piece piece-3 step-3`]" v-if="stepPiece3 <= 3" @click="stepPiece3 = 4; icecrackoneSound.play()" />
      <div :class="[`piece piece-3 step-2`]" v-if="stepPiece3 <= 2" @click="stepPiece3 = 3; icecracktwoSound.play()" />
      <div :class="[`piece piece-3 step-1`]" v-if="stepPiece3 === 1" @click="stepPiece3 = 2; icecrackoneSound.play()" />

      <div :class="[`piece piece-4 step-4`]" v-if="stepPiece4 <= 4" @click="stepPiece4 = 5; icecrackfourSound.play()" />
      <div :class="[`piece piece-4 step-3`]" v-if="stepPiece4 <= 3" @click="stepPiece4 = 4; icecrackoneSound.play()" />
      <div :class="[`piece piece-4 step-2`]" v-if="stepPiece4 <= 2" @click="stepPiece4 = 3; icecracktwoSound.play()" />
      <div :class="[`piece piece-4 step-1`]" v-if="stepPiece4 === 1" @click="stepPiece4 = 2; icecrackoneSound.play()" />
    </div>
    <div v-if="snowWashedAway && stepPiece1 == 1 && stepPiece2 == 1 && stepPiece3 == 1 && stepPiece4 == 1" class="hand"></div>
  </div>
</template>

<script>
import sparkleEffect from '@/mixins/sparkleEffect'
import SnowEffect from '@/components/SnowEffect'

export default {
  components: { SnowEffect },
  mixins: [ sparkleEffect ],
  props: {
    snowWashedAway: Boolean
  },
  data () {
    return {
      totalPiecesToCrush: 4,
      stepPiece1: 1,
      stepPiece2: 1,
      stepPiece3: 1,
      stepPiece4: 1,
    }
  },
  computed: {
    iceCrushed () {
      return this.stepPiece1 > this.totalPiecesToCrush && this.stepPiece2 > this.totalPiecesToCrush && this.stepPiece3 > this.totalPiecesToCrush && this.stepPiece4 > this.totalPiecesToCrush
    },
    crushStarted () {
      return this.stepPiece1 > 1 || this.stepPiece2 > 1 || this.stepPiece3 > 1 || this.stepPiece4 > 1
    }
  },
  watch: {
    crushStarted () {
      if (this.crushStarted) {
        this.updateSessionProgress(
          { step: 'ice_layer', trigger: 'start' }
        )
      }
    },
    iceCrushed () {
      if (this.iceCrushed) {
        this.updateSessionProgress(
          { step: 'ice_layer', trigger: 'finish' }
        )
        this.$emit('iceCompletelyCrushed')
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.ice-wrapper
  overflow: hidden
  align-items: center
  justify-content: center
  z-index: 200

#sparkleCanvas
  position: absolute
  top: 0
  left: 0
  z-index: 300
  pointer-events: none

.ice
  width: 420px
  height: 900px
  position: absolute

.piece
  position: absolute
  background-size: contain
  background-repeat: no-repeat

  &.piece-1
    width: 420px
    height: 294px
    top: 0
    left: 0
    z-index: 14

    @for $i from 1 through 4
      &.step-#{$i}
        background-image: url("~@/assets/images/eisschollen/01_oben/oben_0#{$i}.png")

  &.piece-2
    width: 225px
    height: 491px
    top: 179px
    left: 0
    z-index: 13

    @for $i from 1 through 4
      &.step-#{$i}
        background-image: url("~@/assets/images/eisschollen/02_links/links_0#{$i}.png")

  &.piece-3
    width: 284px
    height: 548px
    top: 131px
    right: 0
    z-index: 13

    @for $i from 1 through 4
      &.step-#{$i}
        background-image: url("~@/assets/images/eisschollen/03_rechts/rechts_0#{$i}.png")

  &.piece-4
    width: 420px
    height: 312px
    bottom: 0
    left: 0
    z-index: 14

    @for $i from 1 through 4
      &.step-#{$i}
        background-image: url("~@/assets/images/eisschollen/04_unten/unten_0#{$i}.png")

.hand
  height: 15rem
  width: 15rem
  z-index: 202
  background-size: contain
  background-repeat: no-repeat
  animation: hand 2s 0s steps(1) infinite
  pointer-events: none
  transform: rotate(-45deg)
  margin-top: -5rem

@keyframes hand
  0%
    background-image: url("~@/assets/images/cursor_ice_break.svg")
  50%
    background-image: url("~@/assets/images/cursor_ice_break_2.svg")
    scale: 0.9

</style>
