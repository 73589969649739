import { render, staticRenderFns } from "./IceLayer.vue?vue&type=template&id=28a4c40e&scoped=true&"
import script from "./IceLayer.vue?vue&type=script&lang=js&"
export * from "./IceLayer.vue?vue&type=script&lang=js&"
import style0 from "./IceLayer.vue?vue&type=style&index=0&id=28a4c40e&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28a4c40e",
  null
  
)

export default component.exports