<template>
  <div class="slider" :style="cssVars">
    <div class="label">{{ labelText }}</div>
    <div class="knob" ref="knob"
      @mouseup="up"
      @touchend="up"
      @mousedown="addUpListeners"
      @touchstart="addUpListeners"
      @mousemove="moveMouse"
      @touchmove="moveTouch"  
    >
      <span class="arrow"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    knobColor: {
      type: String,
      default: '#CCC'
    },
    arrowColor: {
      type: String,
      default: '#FFF'
    },
    labelText: {
      type: String,
      default: 'slide to unlock'
    }
  },
  data() {
    return {
      progress: 0,
      lockInProgress: 70,
      margin: 2,
      width: 0,
      minX: 0,
      maxX: 0,
      threshold: 0
    };
  },
  computed: {
    cssVars() {
      return {
        '--knob-color': this.knobColor,
        '--arrow-color': this.arrowColor
      };
    }
  },
  mounted() {
    this.init()
  },
  destroyed() {
    this.removeUpListeners()
  },
  methods: {
    init() {
      this.width = this.$refs.knob.clientWidth
      this.minX = this.margin
      this.maxX = this.$refs.knob.parentElement.clientWidth - this.width - this.margin
      this.threshold = parseInt(this.width / 2) + 10
      this.progress = 1
      this.moveKnobTo(1)
    },

    moveMouse(event) {
      this.moveKnob(event, event.pageX)
    },

    moveTouch(event) {
      this.moveKnob(event, event.changedTouches[0].pageX)
    },

    moveKnob(event, offset) {
      let el = event.currentTarget
      let parentLeft = el.parentElement.offsetLeft
      let x = offset - parentLeft - this.width / 2
      x = x < this.minX ? this.minX : x > this.maxX ? this.maxX : x

      this.progress = parseInt((x / this.maxX) * 100)
      this.moveKnobTo(x)
    },

    moveKnobTo(x) {
      this.$refs.knob.style.left = `${x}px`
      this.$refs.knob.previousElementSibling.style.opacity = Math.max(0, 1 - x / this.threshold)
    },

    up() {
      this.removeUpListeners();

      if (this.progress >= this.lockInProgress) {
        this.unlock()
      } else {
        this.reset()
      }
    },

    unlock() {
      // TODO: Allow resetting slider from the outside.
      //       Then move knob to max and reset it when invisible.
      // this.moveKnobTo(this.maxX)
      this.moveKnobTo(this.minX)
      this.$emit('unlocked');
    },

    reset() {
      this.$refs.knob.classList.add('is-animated')
      this.moveKnobTo(this.minX)
      window.setTimeout(() => this.$refs.knob.classList.remove('is-animated'), 200)
    },

    addUpListeners() {
      window.addEventListener('mouseup', this.up, false);
      window.addEventListener('mousemove', this.up, false);
      window.addEventListener('touchend', this.up, false);
      window.addEventListener('touchcancel', this.up, false);
    },

    removeUpListeners() {
      window.removeEventListener('mouseup', this.up);
      window.removeEventListener('mousemove', this.up);
      window.removeEventListener('touchend', this.up);
      window.removeEventListener('touchcancel', this.up);
    },
  },
};
</script>

<style lang="sass" scoped>
.slider  
  margin: 10px
  padding-left: 50px
  position: relative
  width: 80%
  height: calc(6rem + 6px)
  display: inline-flex
  justify-content: center
  align-items: center
  background-image: url("~@/assets/images/slider/slider_bg.png")
  background-size: contain
  background-repeat: no-repeat
  width: 265px
  height: 55px

.label
  font-size: 14px
  position: relative
  width: 100%
  text-transform: uppercase
  text-align: right
  padding-right: 4rem
  font-weight: bold

.knob
  position: absolute
  cursor: pointer
  margin-left: -10px
  background-image: url("~@/assets/images/slider/slider_button.png")
  background-size: 100%
  width: 73px
  height: 76px

  &.is-animated
    transition: 0.2s ease-out left
    
</style>
