<template>
  <div class="age-check viewport">
    <div class="viewport is-fullscreen center">
      <div class="abs-10">
        <img src="@/assets/images/logo_new.png" />
      </div>
      <div class="abs-8" />
      <div class="flex">
        <h1 @click="toggleDebug">{{ $t("age_check.headline") }}</h1>
        <!-- {{ this.$i18n.locale }} -->
        <div>{{ $t("age_check.intro") }}</div>
        <br><br>
        
        <div v-if="tooYoung">
          <div>
            <strong>{{ $t("age_check.too_young") }}</strong>
          </div>
        </div>
        <div v-else>
          <div>
            <strong v-html="$t('age_check.are_you_18')"></strong>
          </div>
          <div class="button" @click="$emit('ageValidated')">
            <span>{{ $t("age_check.yes") }}</span>
          </div>
          <div class="button" @click="tooYoung = true">
            <span>{{ $t("age_check.no") }}</span>
          </div>
        </div>
      </div>
      
      <!-- <div class="abs-10" v-if="debug">
        <div>{{ currentWin }}</div>
        <span class="orange" @click="setWin('shot')">Shot</span>
        |
        <span class="orange" @click="setWin('tattoo')">Tattoo</span>
        |
        <span class="orange" @click="setWin('nothing')">Nothing</span>

        <br>
        <br>

        <span class="orange" @click="toggleDebug">Hide debug</span>
        |
        <span class="orange" @click="jumpToFinish">Jump to win</span>
      </div> -->

      <div class="abs-10">
        <div class="language-buttons" >
          <!-- <div @click="changeLanguage('sk')"> -->
          <div @click="changeLanguage('sk')" v-if="this.$store.state.country !== 'cz'">
            <img src="@/assets/images/flags/sk.svg" class="language-flag" />
          </div>
          <div @click="changeLanguage('cz')" v-if="this.$store.state.country === 'cz'">
          <!-- <div @click="changeLanguage('cz')"> -->
            <img src="@/assets/images/flags/cz.svg" class="language-flag" />
          </div>
          <div @click="changeLanguage('en')">
            <img src="@/assets/images/flags/en.svg" class="language-flag" />
          </div>
        </div>      
      </div>  
      <div class="abs-5 privacy">
        <span v-if="this.$store.state.country === 'cz'" v-html="$t('age_check.rules_cz')"></span>
        <span v-else v-html="$t('age_check.rules_sk')"></span>
      </div>  
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      debug: false,
      tooYoung: false
    }
  },
  computed: {
    currentWin () {
      return this.$store.state.win
    }
  },
  methods: {
    jumpToFinish () {
      this.$emit('debug')
    },
    toggleDebug () {
      this.debug = !this.debug
    },
    setWin (win) {
      this.$store.commit('setWin', win)
    },
    changeLanguage (lang) {
      this.$i18n.locale = lang
      this.$store.commit('setLanguage', lang)
    }
  },
}
</script>

<style lang="sass" scoped>
.age-check
  z-index: 300
  background-image: url("~@/assets/images/bg-age-check.jpg")
  background-color: black

.logo
  height: 10rem
  margin: 2rem 0

h1
  text-align: center
  text-transform: uppercase
  margin-top: 4rem
  margin-bottom: 2rem
  font-size: 3.5rem

.button
  border: 2px solid
  display: inline-block
  padding: 1.4rem 2rem
  margin-top: 4rem
  text-transform: uppercase
  font-weight: bold
  cursor: pointer
  margin: 4rem 1rem
  width: 10rem

.language-buttons
  display: flex
  justify-content: center
  margin-top: 2rem

  img
    margin: 0 2rem
    height: 3rem !important

.privacy
  align-items: center
  justify-content: center
  text-align: center
  z-index: 300
  padding: 2rem
  position: absolute
  bottom: 0
  width: 100%
  left: 0
  font-size: 1.5rem

  a
    color: red
    text-decoration: none
    font-weight: bold

</style>
