const client = require('@/client')

export default {
  data () {
    return {
      fingerprint: null,
      userData: null
    }
  },
  computed: {
    sessionIdentifier () {
      return this.$store.state.session
    },
    currentGameId () {
      if (this.$store.state.language === 'sk') {
        return process.env.VUE_APP_GAME_ID_SK
      } else if (this.$store.state.language === 'cz') {
        return process.env.VUE_APP_GAME_ID_CZ
      }

      // defaults to SK
      return process.env.VUE_APP_GAME_ID_SK
    }
  },
  methods: {
    async markSessionAsFinished () {
      if (!this.sessionIdentifier) {
        console.log("Fatal: No session retrieved. Cannot set game to finished.")
        return false
      }

      const data = {
        mark_session_as_finished: true
      }

      const res = await client.put(`public/games/${this.currentGameId}/sessions/${this.sessionIdentifier}`, data)

      if (res) {
        console.log("session marked as finished")
      }
    },
    async updateSessionProgress (data) {

      if (!this.currentGameId) {
        console.log("No game id retrieved. Cannot track.")
        return false
      }

      if (!this.sessionIdentifier) {
        console.log("Fatal: No session retrieved. Cannot track.")
        return false
      }

      const res = await client.put(`public/games/${this.currentGameId}/sessions/${this.sessionIdentifier}`, { progress: data })

      if (res) {
        console.log(data)
        console.log("session progress updated")
      }
    }
  }
}
