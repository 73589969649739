<template>
  <div :class="['viewport', 'is-fullscreen', 'center', 'for-' + this.$store.state.country]">
    <div class="abs-10 p-3">
      <img src="@/assets/images/logo_new.png" class="logo" />
    </div>
    
    <div class="abs-2"></div>
    <div class="abs-6 flex pt-12">
      <h1>{{ $t('game_finished.thanks_for_playing') }}</h1>
    </div>
    <div class="flex"></div>
    <div class="abs-8">      
      <img src="@/assets/images/final_screen/sk/claim.png" v-if="$store.state.language === 'sk'" />
      <img src="@/assets/images/final_screen/cz/claim.png" v-else-if="$store.state.language === 'cz'" />
      <h2 v-else>{{ $t('game_finished.come_back_tomorrow') }}</h2>
    </div>
    <div class="abs-5"></div>
    
    <div class="abs-2 pb-3">
      <img class="responsibly-claim" src='@/assets/images/drink-responsibly_sk.svg' v-if="$store.state.language === 'sk'" />
      <img class="responsibly-claim" src='@/assets/images/drink-responsibly_cz.svg' v-else-if="$store.state.language === 'cz'" />
      <img class="responsibly-claim" src='@/assets/images/drink-responsibly.svg' v-else />
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/main.sass"

.viewport
  background-size: cover
  background-position: center
  overflow: hidden

  &.for-cz
    background-image: url("~@/assets/images/final_screen/cz/background.jpg")
  
  &.for-sk
    background-image: url("~@/assets/images/final_screen/sk/background.jpg")

h1, h2
  text-transform: uppercase
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3), 0px 0px 20px #040703

h2
  font-size: 2.5rem
</style>
