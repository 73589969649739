<template>
  <div class="ice-cold-wrapper viewport is-fullscreen">
    <SnowEffect />
    <div class="viewport is-fullscreen">
      <slot :init="init"></slot>
      <canvas
        id="scratchCanvas"
        ref="scratchCanvas"
        @mousedown="mouseDown"
        @mousemove="mouseMove"
        @touchstart="touchDown"
        @touchmove="touchMove"
      />
    </div>

    <div class="viewport is-fullscreen pointer-events-none" v-if="showIntro">
      <div class="intro-front viewport is-fullscreen">
        <div class="abs-10 center">
          <img src="@/assets/images/logo_new.png" class="logo">
        </div>
        <div class="abs-30 py-6">
          <h1>
            <div class="decor"></div>
            <span v-html="$t('scratch_area.unveil_the_secret')"></span>
          </h1>
        </div>
        <div class="abs-12 center">
          <img src="@/assets/images/snow_wipe.svg" class="swipe">
        </div>
        <div class="flex">
        </div>
        <!-- <div class="abs-2" style="color: yellow">
          {{ this.$store.state.win[0] }}
        </div> -->
        <div class="abs-5 center">
          <img src="@/assets/images/powered_by_pocketrocket.png">
        </div>
      </div>
      <div class="intro-back viewport is-fullscreen" />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import snowPattern from '@/assets/images/snow-pattern.jpg'
import SnowEffect from '@/components/SnowEffect'
import getWin from '@/mixins/getWin'

export default {
  components: { SnowEffect },
  mixins: [ getWin ],
  name: 'VueScratchable',
  props: {
    getPercentageCleared: {
      type: Boolean,
      default: false,
    },
    percentageStride: {
      type: Number,
      default: 150,
    },
  },
  data() {
    return {
      lastSwipeSoundPlayed: 'two',
      showIntro: true,
      brushSize: 100,
      brushShape: 'round',
      canvas: null,
      context: null,
      isPressed: false,
      offset: {
        top: 0,
        left: 0,
      },
      position: {
        currX: 0,
        currY: 0,
        lastX: 0,
        lastY: 0,
      },
      observer: null,
      initFlag: false,
      hideOptions: {
        type: 'pattern',
        src: snowPattern,
        repeat: 'no-repeat'
      },
    };
  },
  mounted() {
    this.canvas = this.$refs.scratchCanvas
    const debounceInit = debounce(() => this.init(), 200);

    this.observer = new MutationObserver((mutations) => {
      mutations.forEach(({ attributeName }) => {
        if (this.initFlag || !attributeName) return;
        debounceInit();
      });
    });
    this.observer.observe(this.$el, {
      childList: true,
      attributes: true,
      characterData: true,
      subtree: true,
    });

    window.addEventListener('mouseup', this.mouseUp, false);
    window.addEventListener('touchend', this.touchUp, false);
    window.addEventListener('touchcancel', this.touchUp, false);

    this.init();

    window.addEventListener('resize', debounce(this.init, 500));

    this.updateSessionProgress(
      { step: 'scratch_area', trigger: 'start' }
    )

    // Get win from server
    this.getWin()
      .then((response) => {
        console.table(response.result)
        this.$store.commit('setWin', response.result.kind)
      })
      .catch((error) => {
        console.error(error);
      });

  },
  destroyed() {
    window.removeEventListener('mouseup', this.mouseUp);
    window.removeEventListener('touchend', this.touchUp);
    window.removeEventListener('touchcancel', this.touchUp);

    window.removeEventListener('resize', debounce(this.init, 500));

    this.updateSessionProgress(
      { step: 'scratch_area', trigger: 'finish' }
    )

    this.observer.disconnect();
  },
  methods: {
    init() {
      this.initFlag = true;
      this.setCanvasSizeAndContext();
      this.$nextTick(() => this.fillArea());
    },

    setCanvasSizeAndContext() {
      this.$nextTick(() => {
        const { width, height } = this.$el.getBoundingClientRect();
        this.canvas.width = Math.ceil(width);
        this.canvas.height = Math.ceil(height);
        this.context = this.canvas.getContext('2d');
      });
    },

    setOffsets() {
      const { top, left } = this.canvas.getBoundingClientRect();
      this.offset.top = top + document.body.scrollTop;
      this.offset.left = left + document.body.scrollLeft;
    },

    async fillArea() {
      const { width, height } = this.context.canvas;
      await this.setFillStyle()
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(` Failed to load image!
            Error: ${err.name}
            Message: ${err.message}
          `);
        });
      this.context.fillRect(0, 0, width, height);
      this.initFlag = false;
    },

    setFillStyle() {
      const {
        type,
        value = '',
        src = '',
      } = this.hideOptions;
      this.context.globalCompositeOperation = 'source-over';

      // eslint-disable-next-line no-return-assign
      if (type === 'color') return new Promise((resolve) => resolve(this.context.fillStyle = value));

      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          this.context.fillStyle = this.context.createPattern(img, "repeat");
          resolve(img);
        };
        img.onerror = (err) => reject(err);
        img.src = src;
      });
    },

    clearArea() {
      const { width, height } = this.context.canvas;
      this.context.globalCompositeOperation = 'destination-out';
      this.context.fillRect(0, 0, width, height);
    },

    draw() {
      const {
        currX,
        currY,
        lastX,
        lastY,
      } = this.position;
      this.context.beginPath();
      this.context.globalCompositeOperation = 'destination-out';
      this.context.lineWidth = this.brushSize;
      this.context.lineJoin = this.brushShape;
      this.context.moveTo(lastX, lastY);
      if (this.isPressed) {
        this.context.lineTo(currX, currY);
      } else {
        this.context.lineTo(currX + 0.01, currY + 0.01);
      }
      this.context.closePath();
      this.context.stroke();
      this.lastPositionHelper(currX, currY);
      this.calculateClearedArea();
    },

    lastPositionHelper(x, y) {
      this.position.lastX = x;
      this.position.lastY = y;
    },
    currentPositionHelper(x, y) {
      this.position.currX = x - this.offset.left;
      this.position.currY = y - this.offset.top;
    },

    mouseDown({ clientX, clientY }) {
      this.setOffsets();
      this.currentPositionHelper(clientX, clientY);
      this.lastPositionHelper(this.position.currX, this.position.currY);
      this.draw();
      this.isPressed = true;
      this.showIntro = false;

      this.playSounds()
    },
    mouseMove({ clientX, clientY }) {
      if (!this.isPressed) return;
      this.currentPositionHelper(clientX, clientY);
      this.draw();
    },
    mouseUp() {
      this.isPressed = false;
    },

    touchDown(event) {
      event.preventDefault();
      const { targetTouches: [{ clientX, clientY }] } = event;

      this.setOffsets();
      this.currentPositionHelper(clientX, clientY);
      this.lastPositionHelper(this.position.currX, this.position.currY);
      this.draw();
      this.isPressed = true;
      this.showIntro = false;

      this.playSounds()
    },
    touchMove(event) {
      event.preventDefault();

      const { targetTouches: [{ clientX, clientY }] } = event;
     
      this.currentPositionHelper(clientX, clientY);
      this.draw();
    },
    touchUp() {
      this.isPressed = false;
    },

    calculateClearedArea() {
      if (!this.getPercentageCleared) return;
      const { width, height } = this.context.canvas;
      const { data, data: { length } } = this.context.getImageData(0, 0, width, height);
      const total = length / this.percentageStride;
      let clearedCount = 0;
      for (let i = clearedCount; i < length; i += this.percentageStride) {
        if (parseInt(data[i], 10) === 0) clearedCount += 1;
      }
      this.$emit('percentage-update', Math.round((clearedCount / total) * 100));
    },
    playSounds () {
      if (this.lastSwipeSoundPlayed === 'one') {
        this.snowswipetwoSound.play()
        this.lastSwipeSoundPlayed = 'two'
      } else {
        this.snowswipeoneSound.play()
        this.lastSwipeSoundPlayed = 'one'
      }
    }
  },
};
</script>

<style lang="sass" scoped>
.ice-cold-wrapper
  overflow: hidden
  align-items: center
  justify-content: center
  z-index: 300

h1
  font-size: 5rem
  line-height: .95

.decor
  height: 0.4em
  width: 9rem
  background: var(--color-orange)
  transform: translate(-2rem, 0.6em)
  z-index: -1
  position: absolute

h2
  font-size: 2.5rem

h1, h2
  font-weight: bold
  text-transform: uppercase

.pointer-events-none
  pointer-events: none

#scratchCanvas
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0

.intro-front
  z-index: 2

.intro-back
  background: linear-gradient(180deg, #9DAEBB 0%, rgba(163, 186, 208, 0) 100%)
  z-index: 1
  color: #ff0000

.swipe
  transform: translate(-1rem, -1rem) rotate(-10deg)
  animation: swipe 2s 0s linear infinite alternate
  transform-origin: bottom
  pointer-events: none

@keyframes swipe
  0%
    transform: translate(-1rem, -1rem) rotate(-10deg)
  100%
    transform: translate(1rem, 1rem) rotate(10deg)

</style>
