<template>
  <div id="app">
    <GameFinished
      v-if="gameFinished"
    />
    <span v-else>
      <AgeCheck
        v-if="step === 'ageCheck'"
        @ageValidated="step = 'scratch'; playBackgroundSound()"
        @debug="debug()"
      />
      <ScratchArea
        v-if="step === 'scratch'"
        getPercentageCleared
        @percentage-update="updateScratchedPercentage"
      >
      </ScratchArea>
      <!-- mount together with ScratchArea -->
      <IceLayer
        :snow-washed-away="snowWashedAway"
        @iceCompletelyCrushed="startFinishAnimation = true"
        v-if="!startFinishAnimation"
      />
      <Finish
        :animation-started="startFinishAnimation"
        @finishGame="step = 'gameFinished'"
      />
    </span>
  </div>
</template>

<script>
import GameFinished from '@/components/GameFinished'
import AgeCheck from '@/components/AgeCheck'
import IceLayer from '@/components/IceLayer'
import ScratchArea from '@/components/ScratchArea'
import Finish from '@/components/Finish'
import createSession from '@/mixins/createSession'
import i18n from '@/plugins/i18n'

export default {
  mixins: [ createSession ],
  components: {
    GameFinished,
    AgeCheck,
    ScratchArea,
    IceLayer,
    Finish,
  },
  computed: {
    gameFinished () {
      // activate if you want to test the cheating mechanism
      return this.$store.state.gameFinished
      // return false
    }
  },
  data() {
    return {
      step: 'ageCheck',
      startFinishAnimation: false,
      snowWashedAway: false,
    }
  },
  async mounted () {
    this.disableScroll()

    // If lang is set from the outside (eg. QR code), use it
    // Otherwise, use the TLD of the current URL (if it's .cz or .sk)
    let lang = new URL(location.href).searchParams.get('lang')
    let tld = location.hostname.split('.').pop()

    if (lang) {
      i18n.locale = lang
      this.$store.commit('setLanguage', lang)
    } else if (['cz', 'sk'].includes(tld)) {
      i18n.locale = tld
      this.$store.commit('setLanguage', tld)    
    }

    // We need to set country as well, because certain features are only
    // available in certain countries (eg. Privacy Policy links and language switcher)
    if (['cz', 'sk'].includes(tld)) {
      this.$store.commit('setCountry', tld)
    } else {
      this.$store.commit('setCountry', 'sk') 
    }

    // outletId is the campaignId
    let campaignId = new URL(location.href).searchParams.get('campaignId')

    if (campaignId) {
      this.$store.commit('setOutletId', campaignId)
    }

    // We start with nothing, win will be retrieved
    this.$store.commit('setWin', 'nothing')

    // set session
    await this.getFingerprintAndInitializeSession(campaignId)

    // initial tracking
    this.updateSessionProgress(
      { step: 'age_check', trigger: 'start' }
    )
  },
  methods: {
    updateScratchedPercentage(percentage) {
      if (percentage > 90) {
        this.step = 'ice'
        this.snowWashedAway = true
      }
    },
    debug () {
      this.step = 'ice'
      this.snowWashedAway = true
      this.startFinishAnimation = true
    },
    playBackgroundSound () {
      this.backgroundSound.volume = 0.3
      this.backgroundSound.loop = true
      this.backgroundSound.play()
    },
  },
};
</script>

<style lang="sass" scoped>
h3
  color: #2c3e50
  text-align: center

a
  color: #2196f3

</style>
