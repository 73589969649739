import { render, staticRenderFns } from "./GameFinished.vue?vue&type=template&id=68b0fd13&scoped=true&"
import script from "./GameFinished.vue?vue&type=script&lang=js&"
export * from "./GameFinished.vue?vue&type=script&lang=js&"
import style0 from "./GameFinished.vue?vue&type=style&index=0&id=68b0fd13&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68b0fd13",
  null
  
)

export default component.exports