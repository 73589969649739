import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const messages = {
  en: {
    age_check: {
      headline: 'Age verification',
      intro: 'To access our Jägermeister app you must be of legal age or older in your country of access.',
      are_you_18: 'Are you <span class="orange">18 or older</span>?',
      yes: 'Yes',
      no: 'No',
      button: 'Yes, I am over <span class="orange">18 years</span> old.',
      too_young: 'Sorry, you are too young to play our game.',
      rules_sk: 'Contest rules can be found at <a href="http://www.jagerpromo.sk" target="_blank">jagerpromo.sk</a>',
      rules_cz: 'Contest rules can be found at <a href="http://www.jagerpromo.cz" target="_blank">jagerpromo.cz</a>'
    },
    finish: {
      sorry: 'Sorry!',
      you_did_not_win: 'You did not win.',
      you_have_won: 'You have won!',
      better_luck_next_time: 'Better luck next time!',
      come_back_tomorrow: 'Come back tomorrow for another chance to win.',
      win_shot: '- 18* ICE COLD<br/>Jägermeister 2CL',
      win_tattoo: 'Temporary Tattoo',
      get_your_win_at_the_bar: 'Get your win at the bar',
      slide_to_redeem: 'Slide to redeem',
      did_you_get_your_win: 'Did you get your win?',
      not_yet: 'Not yet',
      got_it: 'Got it!',
      caution: 'CAUTION:',
      by_accepting_you_redeem: 'By accepting you instantly redeem your win for today.'
    },
    game_finished: {
      thanks_for_playing: 'Thank you for playing',
      come_back_tomorrow: 'Come back tomorrow for another chance to win!'
    },
    scratch_area: {
      unveil_the_secret: 'WIPE OFF THE FROST,<br>BREAK THE ICE<br>AND WIN'
    },
    slider: {
      thanks_for_playing: 'Thank you for playing',
      come_back_tomorrow: 'Come back tomorrow for another chance to win!'
    },
  },
  sk: {
    age_check: {
      headline: 'Overenie veku',
      intro: 'Ak chceš získať prístup k našej hre, musí ti byť 18+.',
      are_you_18: 'UŽ TI <span class="orange">BOLO 18</span>?',
      yes: 'ÁNO',
      no: 'NIE',
      button: 'Yes, I am over <span class="orange">18 years</span> old.',
      too_young: 'Je nam ľúto, na hranie hry si príliš mladý/á..',
      rules_sk: '<a href="http://www.jagerpromo.sk" target="_blank">Pravidlá nájdeš na</a>',
      rules_cz: '<a href="http://www.jagerpromo.cz" target="_blank">Pravidlá nájdeš na</a>'
    },
    finish: {
      sorry: 'Sorry!',
      you_did_not_win: 'Bohužiaľ, dnes to nevyšlo…',
      you_have_won: 'Gratulujeme! Vyhral/a si!',
      better_luck_next_time: 'Svoje šťastie môžeš skúsiť nabudúce!',
      come_back_tomorrow: 'Zahraj si opäť aj zajtra!',
      win_shot: '- 18* ICE COLD<br/>Jägermeister 2CL',
      win_tattoo: 'Dočasné tetovanie',
      get_your_win_at_the_bar: 'Získaj svoju výhru u obsluhy',
      slide_to_redeem: 'Uplatni si výhru',
      did_you_get_your_win: 'ZÍSKAL/A SI UŽ SVOJU VÝHRU?',
      not_yet: 'EŠTE NIE',
      got_it: 'ÁNO!',
      caution: 'Upozornenie:',
      by_accepting_you_redeem: 'Získaním výhry si okamžite uplatníte vašu výhru v dané herné kolo.'
    },
    game_finished: {
      thanks_for_playing: 'VĎAKA ZA HRU!',
      come_back_tomorrow: 'Zahraj si opäť aj zajtra!'
    },
    scratch_area: {
      unveil_the_secret: 'ODSTRÁŇ NÁMRAZU<br>ROZBI ĽAD<br>A VYHRAJ'
    },
    slider: {
      thanks_for_playing: 'VĎAKA ZA HRU!',
      come_back_tomorrow: 'Zahraj si opäť aj zajtra!'
    },
  },
  cz: {
    age_check: {
      headline: 'Ověření věku',
      intro: 'Pokud chceš získat přístup k naší hře, musí ti být 18+.',
      are_you_18: 'UŽ TI <span class="orange">BYLO 18</span>?',
      yes: 'ANO',
      no: 'NE',
      button: 'Yes, I am over <span class="orange">18 years</span> old.',
      too_young: 'Je nám líto, na hraní hry jsi příliš mladý/a.',
      rules_sk: '<a href="http://www.jagerpromo.sk" target="_blank">Pravidla najdeš na</a>',
      rules_cz: '<a href="http://www.jagerpromo.cz" target="_blank">Pravidla najdeš na</a>'
    },
    finish: {
      sorry: 'Sorry!',
      you_did_not_win: 'Bohužel, dneska to nevyšlo…',
      you_have_won: 'Gratulujeme! Vyhrál/a jsi!',
      better_luck_next_time: 'Své štěstí můžeš zkusit příště!',
      come_back_tomorrow: 'Zahraj si opět i zítra!',
      win_shot: '- 18* ICE COLD<br/>Jägermeister 2CL',
      win_tattoo: 'Dočasné tetování',
      get_your_win_at_the_bar: 'Řekni si o svou výhru u obsluhy',
      slide_to_redeem: 'Uplatni svou výhru',
      did_you_get_your_win: 'ZÍSKAL/A JSI UŽ SVOU VÝHRU?',
      not_yet: 'JEŠTĚ NE',
      got_it: 'ANO!',
      caution: 'Upozornění:',
      by_accepting_you_redeem: 'Získáním výhry si okamžitě uplatníte vaši výhru v daný herní den.'
    },
    game_finished: {
      thanks_for_playing: 'DÍKY ZA HRU!',
      come_back_tomorrow: 'Zahraj si opět i zítra!'
    },
    scratch_area: {
      unveil_the_secret: 'ODSTRAŇ NÁMRAZU<br>ROZBIJ LED<br>A VYHRAJ'
    },
    slider: {
      thanks_for_playing: 'DÍKY ZA HRU!',
      come_back_tomorrow: 'Zahraj si opět i zítra!'
    },
  },
}

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
})

export default i18n
