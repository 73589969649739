const client = require('@/client')

export default {
  computed: {    
    outletIdentifier () {
      return this.$store.state.outletId
    },
  },
  methods: {
    async getWin () {
      if (!this.outletIdentifier) {
        throw new Error("No Outlet ID found. Cannot retrieve win result from server.");
      }

      // Use the following data object to test the "win" response
      // const data = {
      //   outlet_id: this.outletIdentifier,
      //   today: '2023-05-12'
      // }
      const data = {
        outlet_id: this.outletIdentifier
      }

      const response = await client.post(
        `public/internal/check_win`,
        data
      )
    
      return response.data;
    }
  }
}
